<template>
    <div class="pathology">
        <div class="title_box">
            <p class="title3">{{title}}</p>
            <p class="title_tip3">TRACE EVIDENCE IDENTIFICATION</p>
        </div>
        <div class="center_content bold">
            《物证类司法鉴定执业分类规定》司法部司规［2020］5号
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            文书鉴定是指鉴定人运用文件检验学的理论、方法和专门知识，对可疑文件（检材）的书写人、制作工具、制作材料、制作方法、内容、性质、状态、形成过程、制作时间等问题进行检验检测、分析鉴别和判断并提供鉴定意见的活动。

        </div>
        <div style="margin:2rem 0" class="flex wrap">
          <div class="col3 flex center" style="margin-bottom:2rem;" v-for="(item,index) in data" :key="index">
            <div class="col5 hvr-forward">
              <div style="margin-bottom:1rem;">
                <img :src="require('../../assets/range/document/'+item[0])">
              </div>
              <p> 
                {{(index-0+1)+'.'+item[1]}}
              </p>
            </div>
          </div>
        </div>
        
        <ConnectBtn></ConnectBtn>
    </div>
</template>
<script>
import mixin from "./mixin.js"
export default {
  mixins:[mixin],
  data(){
    return{
      data:[
        ['1-1.jpg','笔迹鉴定'],
        ['1-2.jpg','印章印文鉴定'],
        ['1-3.jpg','印刷文件鉴定'],
        ['1-4.jpg','篡改（污损）文件鉴定'],
        ['1-5.jpg','文件形成方式鉴定'],
        ['1-6.jpg','特种文件鉴定'],
        ['1-7.jpg','朱墨时序鉴定'],
        ['1-8.jpg','文件材料鉴定'],
        ['1-9.jpg','文本内容鉴定'],
        ['1-10.jpg','基于痕迹的文件形成时间鉴定特征'],
        ['1-11.jpg','基于鉴定材料特性的文件形成时间'],
      ],
      // title:''
    }
  },
  created(){
    console.log(this.title)
    // this.title = this.$route.meta.title
  }
}
</script>